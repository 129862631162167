import React from "react";
import { Card, Text, ScrollArea } from "@mantine/core";

const TermsAndConditions = () => {
  return (
    <Card padding="lg" style={{ margin: "auto", maxWidth: "800px" }}>
      <ScrollArea style={{ height: "100%" }}>
        {/* Gets handled by newly implemented modal title logic
        <Title order={2} align="center">
          Terms and Conditions
        </Title>
  */}
        <Text>
          <h3>Welcome to PitchQ!</h3>
          <p>
            These PitchQ Terms of Service ("Terms") are an agreement entered
            between you and PolyQ, Inc and its affiliates (collectively "PolyQ,"
            "We," or "us") in connection with your use of the PitchQ service
            ("PitchQ"). In these Terms, "you" refers to you as an individual and
            to the entity you represent. By using PitchQ, you consent to these
            PitchQ Terms of Service.
          </p>
          <h3>PolyQ's PolyQ Platform Terms of Service</h3>
          <p>
            PolyQ's PolyQ Platform Terms of Service also apply to your use of
            PitchQ. In the event of a conflict between the PolyQ Platform Terms
            of Service and the PitchQ Terms of Service, the PitchQ Terms of
            Service will apply. If you purchase a subscription to PitchQ, then
            the Subscriber Terms shall apply as well. PitchQ is considered part
            of the "PolyQ Platform" for purposes of the PolyQ Platform Terms of
            Service. Capitalized terms used but not defined in these PitchQ
            Terms of Service have the meanings defined in the PolyQ Platform
            Terms of Service.
          </p>
          <h3>IMPORTANT ARBITRATION NOTICE</h3>
          <p>
            IF YOU ARE IN THE UNITED STATES OR CANADA, YOU AGREE THAT DISPUTES
            BETWEEN YOU AND PolyQ WILL BE RESOLVED BY BINDING, INDIVIDUAL
            ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS
            ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN THIS PROCESS,
            SOME EXCEPTIONS, AND HOW YOU CAN OPT OUT OF ARBITRATION IN SECTION
            10 OF THE PolyQ PLATFORM TERMS OF SERVICE.
          </p>
          <h3>1. A Platform for Startup validation and creation (PitchQ)</h3>
          <p>
            PitchQ is a platform that enables you to explore and leverage
            various tasks powered by third-party Large Language Models ("LLMs")
            and developers, including OpenAl and Anthropic. Each task has its
            own description in its profile. Usage guidelines for all tasks can
            be found by visiting https://PitchQ.com/usage_guidelines or in your
            settings. PitchQ is a platform that enables users to access various
            third-party tasks, but PitchQ does not provide and is not
            responsible for the content or services available from these tasks.
          </p>
          <h3>2. Age Requirements</h3>
          <p>
            Use of PitchQ by anyone under 13 years of age is prohibited. You
            represent that you are at least the age of majority in the
            jurisdiction where you live or, if you are not, your parent or legal
            guardian must consent to these PitchQ Terms of Service and affirm
            that they accept these Terms on your behalf and bear responsibility
            for your use. Tasks accessed via PitchQ may produce content that is
            not suitable for minors. If you are accepting these PitchQ Terms of
            Service on behalf of someone else or an entity, you confirm that you
            have the legal authority to bind that person or entity to these
            Terms.
          </p>
          <h3>3. Your Use of PitchQ</h3>
          <p>
            3.1 Use of PitchQ. Subject to your compliance with these Terms, we
            grant you a personal, non-exclusive, non-transferable right to use
            PitchQ and its tasks.
          </p>
          <p>
            3.2 User Acknowledgments. You acknowledge and agree that: tasks
            accessed via PitchQ may not generate accurate information and
            information provided may be out of date. You should independently
            verify responses or advice provided by any tasks on PitchQ. PolyQ
            does not provide any warranties or guarantees as to the accuracy of
            any information provided via PitchQ; PitchQ and tasks on PitchQ are
            subject to modification and alteration, and may contain errors,
            design flaws, or other issues; Use of PitchQ or tasks on PitchQ may
            result in unexpected results, loss of data or communications, or
            other anticipated or unanticipated damage or loss to you; PitchQ or
            tasks on PitchQ may not operate properly, be in final form, or be
            fully functional; your access to PitchQ or the tasks is not
            guaranteed and PitchQ and the tasks are provided on an AS IS basis;
          </p>
          <p>
            3.3 Restricted Uses. You agree not to use PitchQ or tasks on PitchQ
            to: Violate the rights of another party or any applicable laws;
            Violate any of these Terms or our policies, or policies of
            third-party providers that power the tasks on PitchQ which can be
            found in the PolyQ profile; Reverse assemble, reverse compile,
            decompile, translate or otherwise attempt to discover the source
            code or underlying components of models, algorithms, and systems of
            PitchQ, the tasks, or their underlying technologies; Abuse, harm,
            interfere with, reverse engineer, or disrupt PitchQ, the tasks, or
            their underlying technologies, such as by accessing or using them in
            fraudulent or deceptive ways, introducing malware, or spamming,
            hacking, or bypassing any protective measures; Use PitchQ or the
            tasks in an automated fashion, such as by exceeding any rate limits
            set forth by us from time to time; Use any method to extract data
            from PitchQ or the tasks, including web scraping, web harvesting, or
            web data extraction methods, other than as permitted by these PitchQ
            Terms of Service; or Represent that Task-Generated Content (as
            defined below) is human-generated.
          </p>
          <p>
            3.4 Feedback. We welcome your feedback and suggestions about how to
            improve PitchQ or tasks on PitchQ. By submitting feedback, you agree
            to grant us the right, at our discretion, to use, copy, disclose,
            create derivative works, display, publish, and otherwise exploit the
            feedback, in whole or part, freely and without any compensation to
            you. Please provide your feedback by emailing us at
            feedback@pitchq.com.
          </p>
          <h3>4. Your Content</h3>
          <p>
            4.1 Your Content. PitchQ may allow you to ask questions, create
            bookmarks, vote or like within PitchQ or outside of PitchQ. All
            material that you upload, publish, or display within PitchQ, and any
            results that you receive in response to your assets (or other
            inputs) from tasks accessible via PitchQ ("Task-Generated Content"),
            will collectively be considered "Your Content." Other users may ask
            questions and receive responses from tasks on PitchQ that are
            similar to or the same as yours. The content that other users
            receive are not considered Your Content.
          </p>
          <p>
            4.2 Your tasks. PitchQ may allow you to create tasks powered by
            third-party LLMs or add your own task to the PitchQ platform for
            users to interact with (each, a "Your tasks"). Your tasks will be
            associated with your PitchQ profile name. You have no obligation to
            create a task, but if you choose to create a task you are
            responsible for ensuring the content is lawful and does not violate
            these Terms, our policies or the policies of any third-party LLMs
            which power your task. PolyQ may, in its sole discretion, remove
            your task if it violates these Terms, our policies or the policies
            of any third-party LLMs which power your PolyQ or applicable law.
            PolyQ takes no responsibility for your tasks, any of its content, or
            others use of your tasks. If you choose to connect Your tasks to
            PitchQ using our APIs, then the PitchQ API Terms apply to your use
            of such APIs. If you choose to participate in the Creator
            Monetization Program, then the program terms will apply to your
            participation in the program.
          </p>
          <p>
            4.3 Ownership. You retain ownership of Your Content, subject to the
            non-exclusive rights granted below.
          </p>
          <p>
            4.4 Your Responsibility. You acknowledge and agree tasks accessible
            via PitchQ answer your questions based on knowledge derived from a
            variety of sources, and that PolyQ does not create or generate any
            PolyQ-Generated Content. PitchQ provides access to several
            underlying technologies, including third-party providers that use
            LLMs. An LLM is a machine learning system that processes and
            generates text. You agree that you are responsible for Your Content
            and Your tasks, including for ensuring that they do not violate any
            applicable law, these Terms (including the Restricted Uses in
            Section 3.3 above), our policies, or the policies of any third-party
            LLMs which power tasks within PitchQ. We reserve the right to block,
            remove, and/or permanently delete Your Content or Your tasks if they
            are in breach of these Terms, our policies, the policies of any
            third-party LLMs which power tasks within PitchQ, or violate any
            applicable law or regulation, or if it creates risk for PolyQ or
            PitchQ or negatively impacts the experience other PitchQ users
          </p>
          <p>
            4.5 Our Use of Your Content. We may use Your Content and Your tasks
            to provide and improve PitchQ, including: Providing your questions
            and tasks to third-party LLMs to generate responses; Displaying Your
            Content to others if you use the sharing features in PitchQ; Making
            available Your tasks for others to use and interact with within
            PitchQ; Promoting your shared content or Your tasks to others;
            Understanding your use of PitchQ to generally improve the PitchQ
            services. We may also need to use or disclose Your Content to comply
            with applicable laws, enforce these PitchQ Terms of Service and our
            policies, and to detect and prevent fraud, security, or technical
            issues, By using PitchQ, you grant us a worldwide, non-exclusive,
            royalty-free, transferable, and perpetual license to use Your
            Content and Your tasks as stated above.
          </p>
          <h3>5. Termination</h3>
          <p>
            You may cease your use of PitchQ or terminate these PitchQ Terms of
            Service at any time for any reason or no reason by deleting your
            account in your settings. We may terminate your access to PitchQ
            and/or these PitchQ Terms of Service at any time for any reason or
            no reason. Any Data collected prior to termination may continue to
            be used in accordance with these Terms. The following provisions
            will survive expiration of termination of these Terms, in addition
            to the provisions set forth in Section 11(e) of the PolyQ Platform
            Terms of Service: Section 3.4 (Feedback), Section 4.3, 4.4 and 4.5
            (Your Content), this Section 5 (Termination), Section 7 (Disclaimers
            and Limitation of Liability) and Section 8 (General).
          </p>
          <h3>6. Privacy</h3>
          <p>
            As detailed in the PitchQ Privacy Policy, we may collect certain
            personal information related to your use of PitchQ (including
            contact information, etc.). Your account information is anonymized
            before being shared with third party LLM providers and developers.
            The third party developers and LLM providers may receive details
            about your interactions with tasks on PitchQ to provide responses
            and to generally improve the services. There is no need to share
            sensitive personal information with the tasks (such as credit card
            information, social security information, etc.). For more
            information about the third party LLM providers, please see the
            respective PolyQ profiles. For more information about PitchQ's
            privacy practices, Please visit the PitchQ Privacy Policy.
          </p>
          <h3>7. DISCLAIMERS AND LIMITATION OF LIABILITY</h3>
          <p>
            Section 8 of the PolyQ Platform Terms of Service is incorporated
            herein by reference, except that Section 8 (e) of the PolyQ Platform
            Terms of Service is replaced with the following: WITHOUT LIMITING
            THE FOREGOING, PolyQ'S MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES
            OR DAMAGES THAT YOU SUFFER RELATED TO OR ARISING OUT OF YOUR USE OF
            THE PitchQ SERVICES OR THESE TERMS IS LIMITED THE AMOUNT YOU PAID TO
            PolyQ IN THE PRIOR TWELVE (12) MONTHS FOR THE PitchQ SERVICES GIVING
            RISE TO THE CLAIM. WE CANNOT GUARANTEE THE AVAILABILITY OF ANY
            tasks, FEATURES OR SERVICE OF PitchQ OR AVAILABLE THROUGH PO. WHILE
            WE ARE CONTINUING TO DEVELOP NEW FEATURES, ADD tasks AND IMPROVE
            PitchQ, WE MAY, WITHOUT NOTICE TO YOU, ADD OR REMOVE FEATURES OR
            tasks, LIMIT USE OR AVAILABILITY OF FEATURES OR tasks ENTIRELY (OR
            JUST IN CERTAIN PLACES OR FOR SOME USERS) IF THEY CREATE RISK TO
            PolyQ, USERS OF PitchQ, THIRD PARTIES POWERING THE tasks, NO LONGER
            MAKE SENSE FROM A BUSINESS PERSPECTIVE OR FOR ANY REASON IN OUR SOLE
            DISCRETION. WE ALSO CANNOT GUARANTEE THAT YOUR USE OF PitchQ WILL BE
            ERROR FREE, DISRUPTED OR THAT YOU WILL NOT EXPERIENCE SERVICE
            OUTAGES. WE ARE NOT LIABLE FOR ANY ERRORS, DISRUPTIONS OR SERVICE
            OUTAGES THAT MAY OCCUR.
          </p>
          <h3>8. General</h3>
          <p>
            You agree that any claim not subject to Arbitration as set forth in
            Section 10 of the PolyQ Platform Terms of Service must be resolved
            in a competent court in Santa Clara County, California and that the
            substantive laws of the State of California in the United States of
            America (excluding conflict of laws rules) will govern these Terms
            and any claim. These Terms will be binding upon and will inure to
            the benefit of your and our heirs, successors, and/or assignees.
          </p>

          <p>Last updated Jan 20, 2024</p>
        </Text>
      </ScrollArea>
    </Card>
  );
};

export default TermsAndConditions;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Call the logout method from your AuthService
    logout(); // This will update the context and trigger a re-render

    // Now redirect to the home page or login page
    navigate("/", { replace: true });
  }, [logout, navigate]);

  // Optionally, you can show a message or a spinner while logging out
  return <div>Logging out...</div>;
};

export default Logout;

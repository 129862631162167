// BasicModal.js
import React from "react";
import { Modal, ScrollArea } from "@mantine/core";

const BasicModal = ({ opened, onClose, children, title, size = "70%" }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
      centered
      size={size}
      overlayProps={{
        backgroundOpacity: 0.5,
        blur: 3,
      }}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;

import React from "react";
import { Card, Text, ScrollArea } from "@mantine/core";

const AboutPage = () => {
  return (
    <Card padding="lg" style={{ margin: "auto", maxWidth: "800px" }}>
      <ScrollArea style={{ height: "100%" }}>
        <Text>
          <h3>Welcome to PitchQ</h3>
          <p>
            PitchQ is a platform that lets people leverage various tasks to help
            validate and create your startup, preparing you for potential
            investors with a wide variety of AI-powered tasks. It is available
            on iOS, Android, MacOS, Windows, and the web.
          </p>

          <h3>AI Powering PitchQ</h3>
          <p>
            The AI on PitchQ is powered by models from several sources, trained
            by different companies. These different models are optimized for
            different tasks, represent different points of view, or have access
            to different knowledge. Some models are fine-tuned versions of
            models created by others. PitchQ currently supports ChatGPT, GPT-4,
            and DALL-E 3 from OpenAI, Claude Instant and Claude 2 from
            Anthropic, StableDiffusionXL from Stability AI, PaLM and Gemini-Pro
            from Google, Llama 2 from Meta, Playground-v2 by Playground,
            Mistral-Medium by Mistral, and many other tasks created by the
            community. We hope to make AI easy for everyone to explore the
            possibilities opened up by new AI models as they are released.
          </p>

          <h3>Limitations and Improvements</h3>
          <p>
            Please keep in mind that the AI available through PitchQ is not
            perfect, so it will be useful in some cases but not in others. We
            will do our best to fix the problems within our control as quickly
            as possible, but it will take time for AI research to continue to
            advance to the point where many of the flaws we see today are
            addressed. Until that happens, we hope the features on PitchQ will
            help you assess, build, and assess your startup and get the notice
            you deserve by investors.
          </p>

          <h3>Building PitchQ</h3>
          <p>
            We will be doing our best to build PitchQ as quickly as possible so
            that everyone can benefit from AI as this incredible technology
            becomes available to the world.
          </p>

          <h3>Explore PitchQ</h3>
          <p>
            The best way to learn about PitchQ is to try it: Visit PitchQ.com to
            download the iOS app, Android app, MacOS app, and Windows app. Thank
            you for using PitchQ! - The PitchQ Team
          </p>

          <h3>Contact and Social Media</h3>
          <p>
            We'd love to hear from you! Please reach out to us using our contact
            form. Find us on Twitter, Instagram, Threads, TikTok, and LinkedIn.
          </p>
        </Text>
      </ScrollArea>
    </Card>
  );
};

export default AboutPage;

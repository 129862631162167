import React, { useState, Suspense } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Group } from "@mantine/core";
import BasicModal from "./BasicModal"; // Adjust the path as needed
import configJson from "../portalConfig.json";

const Footer = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [ModalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const openModalWithComponent = (componentName, label) => {
    const Component = React.lazy(() => import(`./${componentName}`));
    setModalContent(<Component onClose={() => setModalOpened(false)} />);
    setModalOpened(true);
    setModalTitle(label);
  };

  const handleLinkClick = (item, event) => {
    if (item.component) {
      event.preventDefault();
      openModalWithComponent(item.component, item.label);
    }
  };
  const sections = configJson.footer.sections;
  const leftItems = sections.filter((section) => section.align === "left");
  const centerItems = sections.filter(
    (section) => !section.align || section.align === "center"
  );
  const rightItems = sections.filter((section) => section.align === "right");

  const renderLinkItems = (items, align) => {
    const linkStyle = {
      margin: "0 10px",
      fontSize: "1rem",
      fontWeight: 500,
      textDecoration: "none",
      padding: "5px 10px",
      borderRadius: "4px",
      transition: "all 0.3s ease",
      color: "#DFC878",
    };

    const hoverStyle = {
      color: "#FFF",
    };

    return (
      <Group position={align} spacing="md">
        {items.map((item) => {
          const isIcon = item.iconUrl;

          return (
            <RouterLink
              key={item.name}
              to={item.path}
              style={linkStyle}
              onMouseEnter={(e) =>
                Object.assign(e.currentTarget.style, hoverStyle)
              }
              onMouseLeave={(e) =>
                Object.assign(e.currentTarget.style, linkStyle)
              }
              onClick={(e) => handleLinkClick(item, e)}
            >
              {isIcon ? (
                <img
                  src={item.iconUrl}
                  alt={item.name}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                item.label || item.content
              )}
            </RouterLink>
          );
        })}
      </Group>
    );
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 0",
        }}
      >
        {renderLinkItems(leftItems, "left")}
        {renderLinkItems(centerItems, "center")}
        {renderLinkItems(rightItems, "right")}
      </Container>

      <Suspense fallback={<div>Loading...</div>}>
        <BasicModal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          title={modalTitle}
        >
          {ModalContent}
        </BasicModal>
      </Suspense>
    </>
  );
};

export default Footer;

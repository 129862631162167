import React, { createContext, useContext, useState, useEffect } from "react";

export const ConfigContext = createContext(null);

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children, configJson }) => {
  const [config, setConfig] = useState(configJson);

  useEffect(() => {
    // Load and parse the JSON configuration here if needed
    setConfig(configJson);
  }, [configJson]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

import LandingPage from "./components/LandingPage";
import ProfilePage from "./components/ProfilePage";
import TasksPage from "./components/TasksPage";
import LoginPage from "./components/LoginPage";
import Logout from "./components/Logout";
import TermsAndConditions from "./components/TermAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AssetsPage from "./components/AssetsPage";
import PaymentPage from "./components/PaymentPage";
import AboutPage from "./components/AboutPage";
import ContactPage from "./components/ContactPage";

export const componentMap = {
  Landing: LandingPage,
  ProfilePage: ProfilePage,
  TasksPage: TasksPage,
  AssetsPage: AssetsPage,
  LoginPage: LoginPage,
  Logout: Logout,
  TermAndConditions: TermsAndConditions,
  PrivacyPolicy: PrivacyPolicy,
  PaymentPage: PaymentPage,
  AboutPage: AboutPage,
  ContactPage: ContactPage,
};

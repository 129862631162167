import React, { createContext, useState, useEffect, useCallback } from "react";
import { fetchProfile } from "./services/apiService"; // Import the fetchProfile function
import AuthService from "./services/authService"; // Import AuthService
import configJson from "./portalConfig.json";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [data, setData] = useState({
    assets: [],
    tasks: [],
    profile: { plan: "free", accountBalance: 0 },
  });

  const updateProfile = useCallback(async () => {
    console.log("Updating profile ...");
    try {
      const profileData = await fetchProfile();
      setData((prevData) => ({ ...prevData, profile: profileData }));
    } catch (error) {
      console.error("Failed to fetch profile:", error);
    }
  }, []);

  // Load data initially
  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      updateProfile();
    }
  }, [updateProfile]);
  const getPlanDetails = useCallback(() => {
    // Convert the input plan name to lowercase for comparison
    const inputPlanName = data.profile.plan.toLowerCase();

    // Find the plan object that matches the input plan name
    const planObject = configJson.subscription.types.find((plan) =>
      Object.keys(plan).includes(inputPlanName)
    );

    // Return details, with fallback to "free" plan if not found
    return planObject
      ? { alias: planObject.alias, description: planObject.description }
      : { alias: "Free", description: "Free plan" };
  }, [data.profile]); // Depend on data.profile to update when profile changes

  // Include getPlanDetails in the context value
  const value = { data, setData, getPlanDetails };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

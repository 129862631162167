import React from "react";
import SvgComponent from "./SvgComponent";

const DualImageDisplay = ({
  fullscreenImageUrl,
  overlayImageUrl,
  onClick,
  isUserAuthenticated,
}) => {
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: isUserAuthenticated ? "pointer" : "default", // Use the passed isAuthenticated prop
  };

  const fullScreenImageStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fullscreenImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: -2,
  };

  return (
    <div style={containerStyle} onClick={onClick}>
      <div style={fullScreenImageStyle} />
      <div>
        <SvgComponent url={overlayImageUrl} />
      </div>
    </div>
  );
};

export default DualImageDisplay;

import { React, Suspense, useState } from "react";
import { Box, Text } from "@mantine/core";
import configJson from "../portalConfig.json";
import DualImageDisplay from "./DualImageDisplay";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
import BasicModal from "./BasicModal";
import LoginPage from "./LoginPage";

const LandingPage = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const { isAuthenticated } = useAuth(); // Use the authentication status

  const handleOverlayClick = () => {
    if (!isAuthenticated) {
      // If the user is not authenticated, open the login modal
      setModalOpened(true);
    } else {
      // Handle other cases if needed, when the user is authenticated
    }
  };
  const ContentBox = ({
    imageSrc,
    hoverImageSrc,
    label,
    navigationLink,
    imageWidth = "100%", // Default width
    imageHeight = "100%", // Default height
  }) => {
    const [currentImage, setCurrentImage] = useState(imageSrc);

    const hasImages = imageSrc && hoverImageSrc;

    return (
      <Link to={navigationLink} style={{ textDecoration: "none" }}>
        <div
          style={{
            zIndex: 3,
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "256px",
            width: "256px",
          }}
          onMouseEnter={() => hasImages && setCurrentImage(hoverImageSrc)}
          onMouseLeave={() => hasImages && setCurrentImage(imageSrc)}
        >
          {hasImages ? (
            <img
              src={currentImage}
              alt={label}
              style={{ width: imageWidth, height: imageHeight }}
            />
          ) : (
            <Text
              style={{ color: "#fff", fontWeight: "bold", paddingTop: "5px" }}
            >
              {label}
            </Text>
          )}
        </div>
      </Link>
    );
  };

  return (
    <Box
      style={{
        minHeight: "80vh", // Full viewport height
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Space between elements
        alignItems: "center", // Align items in the cross axis (center)
      }}
    >
      <DualImageDisplay
        fullscreenImageUrl={configJson.landingPage.fullBackground}
        overlayImageUrl={configJson.landingPage.overlayImage}
        onClick={handleOverlayClick} // Add the onClick handler
        isUserAuthenticated={isAuthenticated}
      />

      <div
        style={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          flexGrow: 1,
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center", // Center ContentBoxes horizontally
            gap: "0rem",
            marginBottom: "0rem", // Add margin at the bottom if needed
          }}
        >
          {configJson.landingPageLinks.map((link, index) => (
            <ContentBox
              key={index}
              imageSrc={link.imageSrc}
              hoverImageSrc={link.hoverImageSrc}
              label={link.label}
              navigationLink={link.navigationLink}
              imageHeight={link.imageHeight}
              imageWidth={link.imageWidth}
            />
          ))}
        </Box>
      </div>
      <BasicModal
        size="auto"
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
      >
        <LoginPage onClose={() => setModalOpened(false)} />
      </BasicModal>
    </Box>
  );
};

export default LandingPage;

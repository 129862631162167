import React from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/authService"; // Adjust the import path as needed

const ProtectedRoute = ({ component: Component }) => {
  if (!AuthService.isAuthenticated()) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" />;
  }

  // Render the component if authenticated
  return <Component />;
};

export default ProtectedRoute;

import React from "react";
import {
  IconCheck,
  IconX,
  IconAlertCircle,
  IconLoader,
} from "@tabler/icons-react";

const rotateStyle = {
  animation: "rotate 2s linear infinite",
  "@keyframes rotate": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
};

const StatusIcon = ({ status }) => {
  switch (status) {
    case "New": // New
      return <IconCheck color="green" />;
    case "Requested": // Requested
      return <IconLoader color="orange" style={rotateStyle} />;
    case "Ready": // Ready
      return <IconCheck color="green" />;
    case "Aborted": // Aborted
      return <IconX color="red" />;
    case "Error": // Error
      return <IconAlertCircle color="red" />;
    default:
      return null;
  }
};

export default StatusIcon;

import React from "react";
import { Card, Text, ScrollArea } from "@mantine/core";

const PrivacyPolicy = () => {
  return (
    <Card padding="lg" style={{ margin: "auto", maxWidth: "800px" }}>
      <ScrollArea style={{ height: "100%" }}>
        <Text>
          {/* Insert your privacy policy content here */}
          <h3>PitchQ Privacy Policy</h3>
          <p>
            This PitchQ Privacy Policy ("Privacy Policy") describes how your
            personal information is processed when vou use PitchQ, a platform
            that lets you communicate with tasks powered by LLMs. This Privacy
            Policy applies to activities by PolyQ, Inc. and its affiliates and
            subsidiaries (collectively "PolyQ" "We" or "Us").
          </p>
          <p>
            The PolyQ Privacy Policy is incorporated by reference. PolyQ Inc. is
            the data controller of your personal information and is responsible
            for providing you with this Privacy Policy. Information We Collect,
            How It Is Used, & The Legal Basis for Processing Account
            Information. We collect your email address and phone number to
            create an account and verify our identity. We also create a user ID
            for your PitchQ account. If you are an existing PolyQ user, your
            name, PolyQ-associated email address, profile photo, and PolyQ user
            ID will be used to link your PolyQ account to your PitchQ account.
            We may use this information to communicate with you about your
            account. This is so we can comply with our contractual obligations
            to you.
          </p>
          <h3>Device Information</h3>
          <p>
            We collect your device type and operating system details for
            analytics and to troubleshoot product issues. This is so we can
            comply with our contractual obligations to you.
          </p>
          <h3>Subscription Billing Data</h3>
          <p>
            We use a third party service provider to collect and process your
            billing information when you subscribe to PitchQ. The information
            our billing provider collects may include your name, billing
            address, your credit or debit card details, phone number, and email
            address. This processing is necessary to comply with our contractual
            obligations to you.
          </p>
          <h3>Advertising</h3>
          <p>
            We collect information about you when you interact with PitchQ ads
            to measure the effectiveness of our ad campaigns. This information
            includes your IP address, advertising ID, and ad interaction
            information. It is in our legitimate business interest to advertise
            our platform. Where necessary, we will obtain your consent before
            engaging in this processing.
          </p>
          <h3>User Research</h3>
          <p>
            We may ask you to help us test new features or participate in
            surveys to help enhance our PitchQ experience. Your participation is
            voluntary and the data we collect is anonymized. With your consent,
            we may use your personal information to communicate with you.
          </p>
          <h3>Interactions with PitchQ</h3>
          <p>
            Our third party LLM providers and third party task developers may
            receive details about your interactions with PitchQ (including the
            contents of your chats, etc.) to provide and generally improve their
            services, which they may process in their legitimate business
            interests. Your PitchQ user account information (e.g., username and
            email) is not shared with any third party LLMs or developers. Third
            party developers that create tasks on PitchQ using APls may view and
            store your chats on their servers in order to train their models
            Keep in mind, any information you provide to the tasks on PitchQ may
            be shared with third party LLM providers and developers powering the
            tasks, and there is no need to share sensitive personal information
            with the tasks (e.g., credit card information, social security
            information, etc.). For more information about the LLM providers and
            task developers, please see the respective task profiles. For more
            information about our privacy and data protection practices,
            including how to exercise your privacy rights, please visit the
            PolyQ Privacy Policy.
          </p>
          <p>
            Contact Us Please contact our Data Protection Officer at
            privacy@pitchq.com if you have questions about this Privacy Policy.
          </p>
          <p>Last Updated: January 19, 2024</p>
        </Text>
      </ScrollArea>
    </Card>
  );
};

export default PrivacyPolicy;

import React, { useState } from 'react';

const TopUpForm = ({ onTopUp }) => {
  const [amount, setAmount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if an amount is selected
    if (amount) {
      // Pass the amount to your server or handle as needed.
      onTopUp(amount);
    } else {
      console.error("Please select an amount");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Top Up Credits</h3>
      <select value={amount} onChange={(e) => setAmount(e.target.value)}>
        <option value="">Select Amount</option>
        <option value="10">10 Credits</option>
        <option value="20">20 Credits</option>
        <option value="50">50 Credits</option>
        <option value="100">100 Credits</option>
        <option value="200">200 Credits</option>
      </select>
      <button type="submit">Top Up</button>
    </form>
  );
};

export default TopUpForm;

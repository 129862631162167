import "@mantine/core/styles.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider, useConfig } from "./ConfigContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute";
import configJson from "./portalConfig.json"; // Assuming the JSON is saved in this file
import { componentMap } from "./ComponentMap";
import { useMantineTheme, MantineProvider } from "@mantine/core";
import { AuthProvider } from "./AuthContext";
import { AppProvider } from "./AppContext";
export const Context = React.createContext();
const RenderRoutes = () => {
  const config = useConfig();
  const theme = useMantineTheme();
  console.log(theme);

  if (!config) return null;

  return (
    <Routes>
      {config.routes.map(({ path, component, access }) => {
        const Component = componentMap[component];
        if (!Component) return null;

        const routeElement =
          access === "Private" ? (
            <ProtectedRoute component={Component} />
          ) : (
            <Component />
          );

        return <Route key={path} path={path} element={routeElement} />;
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

function App() {
  const [reloadAssets, setReloadAssets] = useState(false);

  useEffect(() => {
    window.onload = function () {
      console.log("Running AP tracker v2...");
      window.APTracker.getInstance({
        clientId: "100230",
        endpoint: "https://ai.adpal.com",
        fp: "off",
      }).init();
    };
  }, []);

  return (
    <Context.Provider value={[reloadAssets, setReloadAssets]}>
      <MantineProvider
        theme={{ ...configJson.themeConfig }}
        withGlobalStyles
        withNormalizeCSS
        useStyles
      >
        <ConfigProvider configJson={configJson}>
          <AuthProvider>
            <AppProvider>
              <Router>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    // background: "linear-gradient(to top, #00000099, #33333366)",
                    zIndex: -3,
                  }}
                >
                  <Header />
                  <div style={{ flex: 1, padding: 20 }}>
                    <RenderRoutes />
                  </div>

                  <div style={{ justifyContent: "flex-end", display: "flex" }}>
                    <Footer />
                  </div>
                </div>
              </Router>
            </AppProvider>
          </AuthProvider>
        </ConfigProvider>
      </MantineProvider>
    </Context.Provider>
  );
}

export default App;

import React, { useState } from "react";
import { Table } from "@mantine/core";
import configJson from "../portalConfig.json";

const SubscriptionForm = ({ onSubscribe }) => {
  const [plan, setPlan] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubscribe(plan);
  };

  const features = configJson.subscription.features;
  const featureTypes = configJson.subscription.types;

  return (
    <form onSubmit={handleSubmit}>
      <h3>Upgrade Subscription</h3>
      <div style={{ maxWidth: "1000px", margin: "auto" }}>
        <Table>
          <thead>
            <tr>
              <th>Feature</th>
              {featureTypes.map((type) => (
                <th
                  key={Object.keys(type)[0]}
                  style={{ width: "calc(100% / featureTypes.length)" }}
                >
                  {Object.keys(type)[0]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <tr key={feature.name}>
                <td>{feature.name}</td>
                {featureTypes.map((type) => (
                  <td key={`${feature.name}-${Object.keys(type)[0]}`}>
                    {feature[Object.keys(type)[0]]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <button type="submit">Upgrade</button>
    </form>
  );
};

export default SubscriptionForm;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/authService";
import { useAuth } from "../AuthContext"; // Import useAuth

import {
  Container,
  Paper,
  TextInput,
  Button,
  Title,
  PinInput,
  Text,
  Space,
  Anchor,
} from "@mantine/core";

const LoginPage = ({ onClose }) => {
  // Added onClose prop
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpError, setOptError] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login function from AuthContext

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!otpRequested) {
      await handleRequestOTP();
    } else {
      await handleVerifyOTP();
    }
  };

  const handleRequestOTP = async () => {
    setIsLoading(true); // Start loading
    try {
      await AuthService.requestOTP(email);
      setOtpRequested(true);
      setOptError(false);
      setOtp("");
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleVerifyOTP = async (otp) => {
    setIsLoading(true); // Start loading
    try {
      console.log("calling AuthService.verifyOTP: " + email + " with " + otp);
      const response = await AuthService.verifyOTP(
        email,
        otp,
        AuthService.onProfileUpdate
      );
      if (response.success) {
        console.log("Got success!!");
        onClose(); // Close the modal
        login();
        window.aptag("event", "conversion", {
          conversion: "Client Login",
          signUpForm: "Login/Signup/OTP Form",
          email: email,
        });
        navigate("/tasks"); // Navigate to tasks or update the page state
      }
    } catch (error) {
      console.log("Show Error State");
      setOptError(true);
      setOtp("");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <Container style={{ minWidth: 300 }} size={420} my={10}>
      <Paper
        component="form"
        onSubmit={handleSubmit}
        p={10}
        mt={10}
        radius="md"
      >
        <Title align="center" style={{ paddingBottom: 50 }}>
          Start
        </Title>
        <Text size="xs">
          Welcome to PitchQ portal. Please provide your email. We will send you
          a One Time Pin (OTP) to validate it and sign you in.
        </Text>
        <Space h="lg" />
        <TextInput
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setOtpRequested(false);
          }}
          placeholder="Enter your email to sign in"
          disabled={!otpError && (otpRequested || isLoading)}
        />

        {otpRequested && (
          <>
            <Space h="lg" />
            <Text fw={700} size="sm">
              One Time Pin (OTP)
            </Text>

            <Text size="xs">
              For your security, please verify your email address. We sent a One
              Time Pin (OTP) to validate to the email below. Please check your
              email box for a message from verify@polyq.ai (it may be in your
              spam). Use the 6 digit pin and enter it below:
            </Text>

            <Space h="lg" />
            <PinInput
              size="lg"
              length={6}
              oneTimeCode
              type="number"
              gap="lg"
              autoFocus
              manageFocus
              error={otpError}
              value={otp}
              onChange={(newValue) => {
                setOtp(newValue);
                setOptError(false);
              }} // Directly use newValue
              disabled={isLoading}
              onComplete={(newValue) => {
                handleVerifyOTP(newValue);
              }}
            />
            {otpError && (
              <Text c="red" align="center">
                Invalid Pin (
                <Anchor
                  c="red"
                  onClick={() => {
                    setOptError(false);
                    setOtp("");
                    handleRequestOTP();
                  }}
                >
                  resend
                </Anchor>
                )
              </Text>
            )}
          </>
        )}
        <Button
          type="submit"
          fullWidth
          mt="xl"
          disabled={isLoading || (otp.length < 6 && otpRequested)}
        >
          {isLoading
            ? "Processing..."
            : otpRequested
            ? "Verify"
            : "Validate email with OTP"}
        </Button>
      </Paper>
    </Container>
  );
};

export default LoginPage;

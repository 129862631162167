class AuthService {
  // Function to request OTP
  async requestOTP(email) {
    try {
      const response = await fetch(
        "https://4z1ehk4sf1.execute-api.us-east-1.amazonaws.com/beta/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to request OTP");
      }
      return data; // Return the response data
    } catch (error) {
      console.error("Error requesting OTP:", error);
      throw error;
    }
  }

  // Function to verify OTP
  async verifyOTP(email, otp, onSuccess) {
    try {
      const response = await fetch(
        "https://4z1ehk4sf1.execute-api.us-east-1.amazonaws.com/beta/validate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, otp }),
        }
      );

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message || "OTP verification failed");
      }
      localStorage.setItem("sessionToken", data.session_token); // Example storage
      console.log("updating authentication");

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(); // Call the callback function
      }

      return data; // Return the response data
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  }

  // Check if user is authenticated
  isAuthenticated() {
    console.log("checking Authentication");
    const token = localStorage.getItem("sessionToken");
    // Check if the token exists and is valid
    if (token) {
      // Here you can add more checks for token validity if needed
      return true;
    } else {
      return false;
    }
  }

  logout() {
    localStorage.removeItem("sessionToken");
    // Additional cleanup if needed
  }
}

export default new AuthService();

import axios from "axios";
import configJson from "../portalConfig.json";
import logout from "./authService";

const apiService = axios.create({
  baseURL: "https://4z1ehk4sf1.execute-api.us-east-1.amazonaws.com/beta", // Replace with your actual base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export const updateStats = async (task) => {
  try {
    // Prepare the data for the POST request
    const postData = {
      token: localStorage.getItem("sessionToken"),
      domain: configJson.domain,
      stats: { task: task.name, taskId: task.id, powerFee: task.powerFee },
    };

    // Perform a POST request
    console.log(postData);
    const response = await apiService.post("/stats", postData);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle different types of errors
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
    } else {
      console.error("Error updating profile:", error);
    }
    //    throw error;
  }
};
export const updateProfile = async (profileData) => {
  try {
    // Prepare the data for the POST request
    const postData = {
      token: localStorage.getItem("sessionToken"),
      domain: configJson.domain,
      ...profileData,
    };

    // Perform a POST request
    const response = await apiService.post("/profile", postData);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle different types of errors
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
    } else {
      console.error("Error updating profile:", error);
    }
    throw error;
  }
};

export const fetchProfile = async () => {
  try {
    const response = await apiService.get("/profile", {
      params: {
        token: localStorage.getItem("sessionToken"),
        domain: configJson.domain,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
    } else {
      console.error("Error fetching tasks:", error);
    }
    throw error;
  }
};

export const fetchTasks = async () => {
  try {
    const response = await apiService.get("/load/domain", {
      params: {
        token: localStorage.getItem("sessionToken"),
        domain: configJson.domain,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
    } else {
      console.error("Error fetching tasks:", error);
    }
    throw error;
  }
};

export const fetchAssets = async () => {
  try {
    const response = await apiService.get(
      `assets?token=${encodeURIComponent(localStorage.getItem("sessionToken"))}`
    );
    return response.data;
  } catch (error) {
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
    } else {
      console.error("Error fetching assets:", error);
    }
    throw error;
  }
};

export const fetchAssetStatus = async (requestId) => {
  try {
    const response = await apiService.get(
      `task/api/status2?requestId=${encodeURIComponent(requestId)}`
    );
    return response.data;
  } catch (error) {
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
    } else {
      console.error("Error fetching asset status:", error);
    }
    throw error;
  }
};

export const executeTask = async (
  taskId,
  assetId,
  parameterList,
  character,
  completion
) => {
  try {
    const requestBody = {
      token: localStorage.getItem("sessionToken"),
      taskId: taskId,
      assetId: assetId,
      parameterList: parameterList,
      character: character,
    };

    console.log("Request Body: " + JSON.stringify(requestBody));

    const response = await apiService.post("/task/api/secureUse", requestBody);

    if (response.status === 200) {
      const responseBody = response.data;
      console.log("Task executed successfully:", responseBody);
      completion(true, responseBody);
    } else {
      console.error("Task execution failed:", response);
      completion(false, null);
    }
  } catch (error) {
    if (error.response && [403, 401].includes(error.response.status)) {
      console.error("403 Forbidden: Access denied.");
      logout();
      completion(false, null); // Optionally pass an error message or code
    } else {
      console.error("Error making the API request:", error);
      completion(false, null);
    }
  }
};

export const getPaymentIntent = async (amount) => {
  const requestBody = {
    amount: amount,
  };
  const response = await apiService.post(
    "/payment/getPaymentIntent",
    requestBody
  );
  if (response.status === 200) {
    console.log("YAY", response);
    console.log("THE DATA", response.data);
    return response.data;
  }
};

export default apiService;

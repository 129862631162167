import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  Button,
  Card,
  Title,
  SimpleGrid,
  Space,
  Fieldset,
  Text,
} from "@mantine/core";
import { AppContext } from "../AppContext";
import configJson from "../portalConfig.json";
import { updateProfile } from "../services/apiService"; // Adjust the path as needed

const ProfilePage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const navigate = useNavigate(); // Initialize the navigate function
  const { data, getPlanDetails } = useContext(AppContext);

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateConfirmation, setUpdateConfirmation] = useState("");

  const handleSave = async () => {
    setIsUpdating(true);
    setUpdateConfirmation("");
    try {
      await updateProfile({
        firstName: firstName,
        lastName: lastName,
        organization: organization,
      });
      setUpdateConfirmation("Profile updated successfully!");
    } catch (error) {
      setUpdateConfirmation("Failed to update profile.");
      // handle error appropriately
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    setFirstName(data.profile.firstName);
    setLastName(data.profile.lastName);
    setOrganization(data.profile.organization);
  }, [data]);

  const handleUpgradePlan = () => {
    //navigate("/payment", { state: { section: "subscription" } });
  };

  const handleBuyCredits = () => {
    //navigate("/payment", { state: { section: "topup" } });
  };

  return (
    <Card
      shadow="sm"
      padding="lg"
      style={{
        margin: "auto",
        width: "1000px",
        maxWidth: "1200px",
      }}
    >
      <SimpleGrid cols={2} spacing="lg" style={{ alignItems: "flex-start" }}>
        <Fieldset
          variant="filled"
          radius="md"
          shadow="sm"
          padding="lg"
          style={{ height: "100%" }}
        >
          <Space h="xl" />
          <Title order={2} align="middle">
            Profile
          </Title>
          <Space h="md" />

          <TextInput
            label="Email"
            value={data.profile.id}
            readOnly
            description="This field can't be change due to security policy."
            variant="filled"
          />
          <Space h="md" />
          <TextInput
            label="First Name"
            value={firstName}
            onChange={(event) => setFirstName(event.currentTarget.value)}
            placeholder="Your first name"
          />
          <Space h="md" />
          <TextInput
            label="Last Name"
            value={lastName}
            onChange={(event) => setLastName(event.currentTarget.value)}
            placeholder="Your last name"
          />
          <Space h="md" />
          <TextInput
            label="Organization Name"
            value={organization}
            onChange={(event) => setOrganization(event.currentTarget.value)}
            placeholder="Your organization"
          />
          <Space h="xl" />
          <Button onClick={handleSave} variant="outline" disabled={isUpdating}>
            {isUpdating ? "Updating..." : "Update Profile"}
          </Button>
          {updateConfirmation && (
            <Text size="xs" style={{ marginTop: "10px" }}>
              {updateConfirmation}
            </Text>
          )}
        </Fieldset>
        <Fieldset
          variant="filled"
          radius="md"
          shadow="sm"
          padding="lg"
          style={{ height: "100%" }}
        >
          <Space h="xl" />

          <Title order={2} align="middle" margin="md">
            Billing Information
          </Title>
          <Space h="md" />
          <TextInput
            label="Current Plan"
            variant="filled"
            description={getPlanDetails().description}
            value={getPlanDetails().alias}
            readOnly
          />
          <Space h="xl" />
          <Button onClick={handleUpgradePlan} disabled variant="outline">
            Upgrade
          </Button>
          <Space h="xl" />
          <Space h="xl" />

          <TextInput
            label="Current Account Balance"
            variant="filled"
            value={
              data.profile.accountBalance
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " credits"
            }
            readOnly
          />
          <Space h="xl" />
          <Button onClick={handleBuyCredits} disabled variant="outline">
            Buy Credits
          </Button>
        </Fieldset>
      </SimpleGrid>
    </Card>
  );
};

export default ProfilePage;

// AuthContext.js
import React, { createContext, useContext, useState } from "react";
import authService from "./services/authService";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    authService.isAuthenticated()
  );

  const logout = () => {
    // Your logout logic here
    authService.logout();
    setIsAuthenticated(false);
  };

  const login = () => {
    // Your login logic here
    setIsAuthenticated(true);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { Table, Loader, ActionIcon, NumberFormatter } from "@mantine/core";
import { fetchAssetStatus, fetchAssets } from "../services/apiService"; // Adjust the path as needed
import { Container } from "@mantine/core";
import configJson from "../portalConfig.json";
import { IconDownload, IconEye } from "@tabler/icons-react";
import StatusIcon from "./StatusIcon"; // Import the StatusIcon component
import { AppContext } from "../AppContext";
import BasicModal from "./BasicModal";
import { Context } from "../App";
import html2pdf from "html2pdf.js";

const AssetsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [blahLoading, setBlahLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const reloadTimer = useRef(null);
  const { data, setData } = useContext(AppContext);
  const [reloadAssets, setReloadAssets] = useContext(Context);

  useEffect(() => {});

  function downloadPDF(content, name) {
    // Get the element you want to convert to PDF
    //    var element = document.getElementById("content");

    // Set up the options for the PDF
    var opt = {
      margin: 1,
      filename: name,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: {
        mode: ["avoid-all", "css", "legacy"], // Avoid breaking pages inside elements if possible
        before: ".page-break-before", // CSS selector for elements to add a page break before
        after: ".page-break-after", // CSS selector for elements to add a page break after
        avoid: "img", // CSS selector for elements to avoid breaking inside
      },
    };

    // Use html2pdf's API to convert it
    html2pdf().set(opt).from(content).save();
  }

  const loadAssets = async () => {
    if (firstLoad) {
      setIsLoading(true);
    }
    try {
      const newAssets = await fetchAssets();
      setData((preData) => ({ ...preData, assets: newAssets }));

      if (newAssets.some((asset) => asset.status === "Requested")) {
        newAssets.forEach((asset) => {
          if (asset.status === "Requested") {
            fetchAssetStatus(asset.requestId);
            console.log("Checking status of " + asset.requestId);
          }
        });

        console.log("Starting 5 sec timer ...");
        // Schedule another call in 5 seconds
        reloadTimer.current = setTimeout(loadAssets, 5000);
      } else {
        console.log("Canceling reload");
        clearTimeout(reloadTimer.current);
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    } finally {
      if (firstLoad) {
        setIsLoading(false);
        setFirstLoad(false);
      }
    }
  };

  useEffect(() => {
    console.log("one");
    loadAssets();
    if (reloadAssets) {
      console.log("two");
      setBlahLoading(true);
      setTimeout(() => {
        setBlahLoading(false);
        setReloadAssets(false);
        loadAssets();
        console.log("three");
      }, 10000);
    }
  }, []);

  const handlePreviewClick = (item) => {
    setModalContent(item.results);
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <div
        style={{ justifyContent: "center", display: "flex", paddingTop: 30 }}
      >
        <Loader color={configJson.primaryColor} />
      </div>
    );
  }

  const rows = data.assets
    .sort((a, b) => new Date(b.created) - new Date(a.created))
    .map((asset, index) => (
      <Table.Tr key={index}>
        <Table.Td style={{ textAlign: "center", verticalAlign: "middle" }}>
          <StatusIcon status={asset.status} />
        </Table.Td>
        <Table.Td>{new Date(asset.created).toLocaleDateString()}</Table.Td>
        <Table.Td>
          {asset.task_name} [{asset.assetId}]
        </Table.Td>
        <Table.Td style={{ textAlign: "center", verticalAlign: "middle" }}>
          {asset.status == "Ready" && (
            <ActionIcon
              variant="subtle"
              radius="lg"
              aria-label="Download"
              onClick={() =>
                downloadPDF(
                  asset.results,
                  asset.task_name + "-" + asset.assetId
                )
              }
            >
              <IconDownload
                style={{ width: "60%", height: "60%" }}
                sTroke={1.5}
                color="#00000050"
                size="sm"
              />
            </ActionIcon>
          )}
        </Table.Td>
        <Table.Td style={{ textAlign: "center", verticalAlign: "middle" }}>
          {asset.status == "Ready" && (
            <ActionIcon
              variant="subtle"
              radius="lg"
              aria-label="Preview"
              onClick={() => handlePreviewClick(asset)}
            >
              <IconEye
                style={{ widTh: "70%", height: "70%" }}
                sTroke={1.5}
                color="#00000050"
                size="sm"
              />
            </ActionIcon>
          )}
        </Table.Td>
        <Table.Td
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            color: "#00000050",
          }}
        >
          {/* Assuming NumberFormatter is a custom component */}
          <NumberFormatter
            suffix=" pq"
            value={asset.power_fee}
            ThousandSeparator
          />
        </Table.Td>
      </Table.Tr>
    ));

  return (
    <Container size="lg" padding="md">
      <Table
        striped
        highlightOnHover
        withTableBorder
        wiThRowBorders={false}
        stickyHeader
        stickyHeaderOffset={45}
      >
        {blahLoading && (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              paddingTop: 30,
            }}
          >
            <Loader color={configJson.primaryColor} />
          </div>
        )}
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Date</Table.Th>
            <Table.Th>Description</Table.Th>
            <Table.Th>Download</Table.Th>
            <Table.Th>Preview</Table.Th>
            <Table.Th>Credits Used</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      <BasicModal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Preview"
        size={"60%"}
      >
        <iframe
          title="Asset Preview"
          id="results"
          style={{ width: "100%", height: "500px" }} // Set appropriate dimensions
          srcDoc={modalContent} // Use srcDoc to set the content of the iframe
        ></iframe>
      </BasicModal>
    </Container>
  );
};

export default AssetsPage;

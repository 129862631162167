import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Text,
  Loader,
  Badge,
  Image,
  Button,
  Flex,
  ScrollArea,
  Tabs,
  Space,
  Modal,
} from "@mantine/core";
import { fetchTasks, updateStats } from "../services/apiService"; // Adjust the path as needed
import configJson from "../portalConfig.json";
import UseTaskModal from "./UseTaskModal";
import { AppContext } from "../AppContext";

const TasksPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [task, setTask] = useState({});
  const { data, setData } = useContext(AppContext);
  const [categories, setCategories] = useState();
  const [activeTab, setActiveTab] = useState(null);
  const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);
  const [upgradeTask, setUpgradeTask] = useState({});

  function openUpgradeModalWithTask(task) {
    setUpgradeTask(task);
    setUpgradeModalOpened(true);
    updateStats(task);
  }
  useEffect(() => {
    const loadTasks = async () => {
      setIsLoading(true);
      try {
        var newTasks = await fetchTasks();
        setData((preData) => ({ ...preData, tasks: newTasks }));
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadTasks();
  }, []);

  useEffect(() => {
    const localTaskCategories = {};

    data.tasks.forEach((task) => {
      try {
        const taskData = {
          ...JSON.parse(task.data),
          name: task.name,
          id: task.id,
        };
        const category = taskData.category || "uncategorized";

        if (!localTaskCategories[category]) {
          localTaskCategories[category] = [];
        }
        localTaskCategories[category].push(taskData);
      } catch (error) {
        // Handle parsing error
        const uncategorized = "uncategorized";
        if (!localTaskCategories[uncategorized]) {
          localTaskCategories[uncategorized] = [];
        }
        localTaskCategories[uncategorized].push(task);
      }
    });
    setCategories(localTaskCategories);
  }, [data.tasks]);

  // Container styles for centering the grid
  const gridContainerStyle = {
    maxWidth: "1300px", // This should match the maximum width of your header
    margin: "auto", // Center the grid container
    padding: "20px", // Add padding
  };

  // Card styles for fixed size
  const cardStyle = {
    height: "256px", // Fixed height for all cards
    width: "384px", // Fixed width for all cards
    backgroundColor: "#00000006",
    border: "2px solid #0000000f",
  };

  const categoryCardStyle = {
    height: "256px", // Fixed height for all cards
    width: "256px", // Fixed width for all cards
    backgroundColor: "#00000099",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };

  const planHierarchy = configJson.subscription.types
    .flatMap(Object.entries)
    .reduce((acc, [plan, value]) => ({ ...acc, [plan]: value }), {});

  function isEligible(task) {
    console.log("task plans:" + task.plans);

    // Retrieve the user's current plan
    const userPlan = data.profile.plan.toLowerCase();
    console.log("user plan:" + userPlan);

    // Retrieve the required plan for the task
    const requiredPlans = task.plans.toLowerCase().split("|");

    // Retrieve all plans from the configJson in a format that can be easily compared
    // Check if the user's plan is equal to or higher than the required plan

    return requiredPlans.some(
      (plan) => planHierarchy[userPlan] >= planHierarchy[plan]
    );
  }

  function openModalWithTask(task) {
    setModalOpened(true);
    setTask(task);
  }

  return (
    <div style={gridContainerStyle}>
      {isLoading ? (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Loader color={configJson.primaryColor} />
        </div>
      ) : (
        <>
          {!activeTab && (
            <Flex
              mih={50}
              //bg="rgba(0, 0, 0, .3)"
              gap="xl"
              justify="center"
              align="center"
              direction="row"
              wrap="wrap"
            >
              {configJson.categories.map((category, index) => (
                <Card
                  radius="lg"
                  key={index}
                  shadow="sm"
                  padding="sm"
                  style={categoryCardStyle}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.border =
                      "2px solid " + configJson.primaryColor)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.border = "2px solid #0000000f")
                  }
                  onClick={() => setActiveTab(category.name)}
                >
                  <Image
                    src={category.imageName}
                    // radius="sm"
                    alt="Icon"
                    w={96}
                    h={96}
                  />
                  <Text size="lg" weight={500} lineClamp={1}>
                    {category.title}
                  </Text>
                  <Text
                    size="xs"
                    lineClamp={6}
                    style={{ overflow: "hidden", padding: "15px" }}
                  >
                    {category.description}
                  </Text>
                </Card>
              ))}
            </Flex>
          )}
          {activeTab && (
            <Tabs
              defaultValue={activeTab}
              active={activeTab}
              onTabChange={setActiveTab}
              variant="outline"
              radius="lg"
              styles={{
                tab: {
                  padding: "10px 30px",
                  fontSize: "1.2rem",
                  marginRight: "20px",
                  marginLeft: "20px",
                },
              }}
            >
              <Tabs.List justify="center">
                {configJson.categories.map((category, index) => (
                  <Tabs.Tab
                    key={category.name}
                    value={category.name}
                    style={{ outline: "none" }}
                  >
                    {activeTab && (
                      <div
                        onClick={() => setActiveTab(category.name)}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.color =
                            configJson.primaryColor)
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.color = "#000")
                        }
                      >
                        {category.title}
                      </div>
                    )}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              <Space h="xl" />
              {Object.keys(categories).map((category) => (
                <Tabs.Panel value={category} key={category}>
                  <Flex
                    mih={50}
                    //bg="rgba(0, 0, 0, .3)"
                    gap="xl"
                    justify="left"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    {categories[category].map((task, index) => (
                      <Card
                        radius="lg"
                        key={index}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.border =
                            "2px solid " + configJson.primaryColor)
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.border = "2px solid #0000000f")
                        }
                        shadow="sm"
                        padding="lg"
                        style={cardStyle}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          <Image
                            src={task.imageName}
                            radius="lg"
                            alt="Icon"
                            fit="scale-down"
                            h={96}
                            w="auto"
                          />
                          <div>
                            <Text fw={700} c="blue">
                              {task.name}
                            </Text>
                            <Text fw={700} size="xs">
                              {task.heading}
                            </Text>
                            <Badge color="green" variant="light">
                              {task.powerFee} credits
                            </Badge>
                          </div>
                        </div>
                        <ScrollArea scrollHideDelay={3500}>
                          <Text size="xs" style={{ marginTop: "10px" }}>
                            {task.description}
                          </Text>
                        </ScrollArea>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "auto",
                          }}
                        >
                          {isEligible(task) ? (
                            <Button
                              color="blue"
                              variant="outline"
                              size="sm"
                              onClick={() => openModalWithTask(task)}
                              style={{ outline: "none" }}
                            >
                              Use
                            </Button>
                          ) : (
                            <Button
                              color="orange"
                              variant="outline"
                              size="sm"
                              style={{ outline: "none" }}
                              onClick={() => openUpgradeModalWithTask(task)} // Add this line
                            >
                              Upgrade
                            </Button>
                          )}
                        </div>
                      </Card>
                    ))}
                  </Flex>
                </Tabs.Panel>
              ))}
            </Tabs>
          )}
          <Modal
            opened={upgradeModalOpened}
            onClose={() => setUpgradeModalOpened(false)}
            title="Upgrade Plan"
            centered
          >
            <div style={{ textAlign: "center" }}>
              <Text align="left">
                To access the task "{upgradeTask.name}", you need to upgrade
                your plan. The upgrades will be available in February 2024. We
                will notify you with an email to {data.profile.id}
              </Text>
              <Button
                onClick={() => setUpgradeModalOpened(false)}
                style={{ marginTop: "20px", display: "inline-block" }}
              >
                Ok
              </Button>
            </div>
          </Modal>
          <UseTaskModal
            modalOpened={modalOpened}
            setModalOpened={setModalOpened}
            task={task}
          />
        </>
      )}
    </div>
  );
};

export default TasksPage;

import React, { useState } from "react";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Accordion, Modal, Button } from "@mantine/core";
import TopUpForm from "./TopUpForm";
import SubscriptionForm from "./SubscriptionForm";
import PaymentModal from "./PaymentModal";

const PaymentPage = () => {
  const [showModal, setShowModal] = useState(false);

  const onTopUp = (amount) => {
    console.log("hello");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Container size={1000} padding="md">
        <Accordion>
          <Accordion.Item label="Top Up Credits">
            <TopUpForm onTopUp={onTopUp} />
          </Accordion.Item>
          <Accordion.Item label="Upgrade Subscription">
            <SubscriptionForm />
          </Accordion.Item>
        </Accordion>
      </Container>

      {/* Mantine Modal */}
      <Modal opened={showModal} onClose={handleCloseModal} title="Payment Modal">
        <PaymentModal handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
};

export default PaymentPage;

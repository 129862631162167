import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PaymentForm from "./PaymentForm";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { getPaymentIntent } from "../services/apiService";
import configJson from "../portalConfig.json";
import CheckoutForm from "./CheckoutForm";
const STRIPE_PUBLIC_KEY =
  "pk_test_51Oa9LBDNPTC3AEZqBK1XFZaNOPXajr2p02DfjT9h6Un0Z3uHJ8AvqR9v4oDdi0gC7gC1tjFOfErPHRsWs6gTXwKA00SR7URUtc";
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
//const stripePromise = loadStripe(configJson.stripe.stripePK);
function PaymentModal({ handleCloseModal }) {
  const [clientSecret, setClientSecret] = useState("");
  //const [stripPromise, setStripePromise] = useState(null);
  async function get() {
    let response = await getPaymentIntent(1000);
    let body = response.body;
    let json = JSON.parse(body);
    let cs = json.clientSecret;
    setClientSecret(cs);
  }
  useEffect(() => {
    get();
  }, []);
  return (
    <div>
      <button
        onClick={handleCloseModal}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faTimes} size="2x" />
      </button>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

export default PaymentModal;

import BasicModal from "./BasicModal";
import { useForm, isNotEmpty } from "@mantine/form";
import { Select, TextInput, Textarea, Button, ScrollArea, Flex, Loader } from "@mantine/core";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { executeTask, fetchAssetStatus, fetchAssets } from "../services/apiService"; // Adjust the path as needed
import configJson from "../portalConfig.json";
import { Context } from "../App";

function UseTaskModal({ modalOpened, setModalOpened, task }) {
  const [data, setData] = useState({ parameters: [] });
  const [reloadAssets, setReloadAssets] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setData({ ...task, parameters: task.parameters || [] });
  }, [task]);

  const submitAction = (values) => {
    console.log("hello son");
    setIsLoading(true);
    executeTask(
      task.id,
      generateAssetId(),
      form.values,
      {
        name: "EMPTY",
        content: "",
      },
      (success, requestId) => {
        setIsLoading(false);
        if (success) {
          setReloadAssets(true);
          navigate("/assets"); // Navigate to tasks or update the page state
          setModalOpened(false);
        } else {
          //TODO: Error message
        }
      }
    );
  };

  const form = useForm({
    initialValues: data.parameters.reduce((acc, param) => {
      acc[param.name] = param.value || ""; // Set a default value (empty string)
      return acc;
    }, {}),
    validate: data.parameters.reduce((acc, param) => {
      acc[param.name] = isNotEmpty();
      return acc;
    }, {}),
  });

  return (
    <BasicModal
      opened={modalOpened}
      onClose={() => setModalOpened(false)}
      title={task.name}
      padding={0} // Remove padding to allow the button to be truly at the bottom
      size="lg" // Adjust the size of the modal as needed
      searchable={ScrollArea.Autosize}
      styles={{
        modal: {
          display: "flex",
          flexDirection: "column",
          height: "80vh", // Adjust the height of the modal
          overflow: "hidden", // Prevents double scrollbar
        },
      }}
    >
      <form onSubmit={form.onSubmit((values) => submitAction(values))}>
        <ScrollArea style={{ flexGrow: 1, overflowY: "auto", height: "calc(500px)" }}>
          {data.parameters &&
            data.parameters.map((param, index) => {
              switch (param.type) {
                case "List":
                  let choicesArray = param.lookup.split("|");

                  return (
                    <Select
                      key={param.id || index}
                      label={param.name}
                      placeholder={param.placeholder}
                      data={choicesArray}
                      // onChange={(_value, option) =>
                      //   handleAnswerChange(param.id, _value)
                      // }
                      searchable
                      withAsterisk
                      nothingFoundMessage="Nothing found..."
                      checkIconPosition="right"
                      {...form.getInputProps(param.name)}
                    />
                  );
                case "Text":
                  return (
                    <Textarea
                      key={param.id || index}
                      label={param.name}
                      placeholder={param.placeholder}
                      autosize
                      withAsterisk
                      minRows={2}
                      maxRows={4}
                      {...form.getInputProps(param.name)}
                    />
                  );
                case "Line":
                  return (
                    <TextInput
                      key={param.id || index}
                      label={param.name}
                      withAsterisk
                      placeholder={param.placeholder}
                      {...form.getInputProps(param.name)}
                    />
                  );
                default:
              }
              return <div>{param.type}</div>;
            })}
        </ScrollArea>
        <Flex
          mih={50}
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "sm", sm: "lg" }}
          justify={{ sm: "center" }}
          align={{ sm: "center" }}
        >
          {isLoading ? <Loader color={configJson.primaryColor} /> : <Button type="submit">Use {task.name}</Button>}
        </Flex>
      </form>
    </BasicModal>
  );
}

function generateAssetId() {
  // Simple example of AssetId generation
  return "ASSET-" + Math.random().toString(36).substr(2, 9).toUpperCase();
}

export default UseTaskModal;

import React from "react";
import { Card, Flex, Space, Text, rem } from "@mantine/core";
import {
  IconDeviceDesktopSearch,
  IconSettingsSearch,
  IconShieldSearch,
  IconShoppingCartSearch,
} from "@tabler/icons-react";

const ContactPage = () => {
  return (
    <Flex
      gap="md"
      justify="center"
      align="flex-start"
      direction="row"
      wrap="wrap"
    >
      <Card
        padding="lg"
        style={{ margin: "auto", width: "500px", height: "240px" }}
        bg="var(--mantine-color-gray-light)"
        radius={20}
      >
        <Flex direction="column" align="center" style={{ height: "100%" }}>
          <IconSettingsSearch
            style={{ width: rem(64), height: rem(64) }}
            stroke={1.0}
          />
          <Space h={20} />
          <Text size="xl">
            <a
              href="mailto:Support@PitchQ.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <b>Support@PitchQ.com</b>
            </a>
          </Text>
          <Space h={10} />
          <Text size="xs">
            <p>
              Do not hesitate to ask any technical or process questions
              regarding the use of PitchQ.com features. Please allow our team
              24h to get back to you.
            </p>
          </Text>
        </Flex>
      </Card>
      <Card
        padding="lg"
        style={{ margin: "auto", width: "500px", height: "240px" }}
        bg="var(--mantine-color-gray-light)"
        radius={20}
      >
        <Flex direction="column" align="center" style={{ height: "100%" }}>
          <IconShoppingCartSearch
            style={{ width: rem(64), height: rem(64) }}
            stroke={1.0}
          />
          <Space h={20} />
          <Text size="xl">
            <a
              href="mailto:Sales@PitchQ.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <b>Sales@PitchQ.com</b>
            </a>
          </Text>
          <Space h={10} />
          <Text size="xs">
            <p>
              Looking for corporate account? Need to understand how to get best
              business value? Contact our experience Sales team. Please allow
              our team 24h to get back to you.
            </p>
          </Text>
        </Flex>
      </Card>{" "}
      <Card
        padding="lg"
        style={{ margin: "auto", width: "500px", height: "240px" }}
        bg="var(--mantine-color-gray-light)"
        radius={20}
      >
        <Flex direction="column" align="center" style={{ height: "100%" }}>
          <IconDeviceDesktopSearch
            style={{ width: rem(64), height: rem(64) }}
            stroke={1.0}
          />
          <Space h={20} />
          <Text size="xl">
            <a
              href="mailto:Startup@PitchQ.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <b>Startup@PitchQ.com</b>
            </a>
          </Text>
          <Space h={10} />
          <Text size="xs">
            <p>
              Do you have Ideas, but not sure what to do next? Let out
              Experienced team to guide you! Please allow our team 24h to get
              back to you.
            </p>
          </Text>
        </Flex>
      </Card>{" "}
      <Card
        padding="lg"
        style={{ margin: "auto", width: "500px", height: "240px" }}
        bg="var(--mantine-color-gray-light)"
        radius={20}
      >
        <Flex direction="column" align="center" style={{ height: "100%" }}>
          <IconShieldSearch
            style={{ width: rem(64), height: rem(64) }}
            stroke={1.0}
          />
          <Space h={20} />
          <Text size="xl">
            <a
              href="mailto:Investor@PitchQ.com"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <b>Investor@PitchQ.com</b>
            </a>
          </Text>
          <Space h={10} />
          <Text size="xs">
            <p>
              Are you tier of looking through piles of ideas and pitches? Let us
              know how we can help. Soon we will be opening Deal-flow for the
              investor community. Please allow our team 24h to get back to you.
            </p>
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

export default ContactPage;

import React, { useState, Suspense, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Group, Image, Anchor } from "@mantine/core";
import configJson from "../portalConfig.json";
import BasicModal from "./BasicModal";
import { useAuth } from "../AuthContext"; // Import useAuth
import { AppContext } from "../AppContext";

const Header = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [ModalContent, setModalContent] = useState(null);

  const [dynamicLabels, setDynamicLabels] = useState({});

  const { isAuthenticated } = useAuth();

  const sections = isAuthenticated
    ? configJson.headers.private.sections
    : configJson.headers.public.sections;

  const { data, getPlanDetails } = useContext(AppContext);

  useEffect(() => {
    const loadDynamicLabels = async () => {
      const labelPromises = sections
        .filter((section) => section.dynamicLabel)
        .map((section) =>
          fetchDynamicLabel(section.dynamicLabel)
            .then((label) => ({ name: section.name, label }))
            .catch(() => ({ name: section.name, label: section.name }))
        ); // Fallback in case of error

      Promise.all(labelPromises).then((results) => {
        const labels = results.reduce((acc, { name, label }) => {
          acc[name] = label;
          return acc;
        }, {});

        setDynamicLabels(labels);
      });
    };

    loadDynamicLabels();
  }, [sections, data]);

  const openModalWithComponent = (componentName) => {
    const Component = React.lazy(() => import(`./${componentName}`));
    setModalContent(<Component onClose={() => setModalOpened(false)} />); // Pass onClose as a prop
    setModalOpened(true);
  };

  const handleLinkClick = (item, event) => {
    if (item.component) {
      event.preventDefault();
      openModalWithComponent(item.component);
    } else {
      // handle normal navigation
    }
  };

  const renderLinkItems = (items, align) => {
    return (
      <Group position={align} spacing="md" style={{ "--group-gap": "0px" }}>
        {items.map((item, index) => {
          const isIcon = item.iconUrl;
          const value = dynamicLabels[item.name] || item.value;
          const label = item.label;

          const valueStyle = item.style || {
            margin: "0 0px",
            fontSize: "1rem",
            fontWeight: 500,
            textDecoration: "none",
            borderRadius: "4px",
            transition: "all 0.3s ease",
            color: "#ffffff",
          };

          const labelStyle = item.labelStyle || {
            margin: "0 5px",
            fontSize: "1rem",
            fontWeight: 500,
            textDecoration: "none",
            borderRadius: "4px",
            transition: "all 0.3s ease",
            color: "#DFC878",
          };

          const hoverStyle = {
            color: item.style?.hoverColor || "#789BDF",
          };

          return (
            <React.Fragment key={item.name || index}>
              {label != null ? <div style={labelStyle}>{label}</div> : <></>}
              <RouterLink
                to={item.path}
                style={{
                  ...valueStyle,
                  display: "flex", // Ensures the content inside is flexibly aligned
                  alignItems: "center", // Vertically centers the content
                  justifyContent: "center", // Optionally, you can also center content horizontally
                }}
                onMouseEnter={(e) =>
                  Object.assign(e.currentTarget.style, hoverStyle)
                }
                onMouseLeave={(e) =>
                  Object.assign(e.currentTarget.style, valueStyle)
                }
                onClick={(e) => handleLinkClick(item, e)}
              >
                {isIcon ? (
                  <img
                    src={item.iconUrl}
                    alt={item.name}
                    style={{
                      width: "35px",
                      height: "35px",
                      marginRight: "0px",
                    }} // Add margin to the right of the image
                  />
                ) : (
                  <span style={{ marginRight: "30px" }}>{value}</span> // If you have a label, adjust its margin here
                )}
              </RouterLink>
            </React.Fragment>
          );
        })}
      </Group>
    );
  };

  const leftItems = sections.filter((section) => section.align === "left");
  const centerItems = sections.filter((section) => section.align === "center");
  const rightItems = sections.filter((section) => section.align === "right");

  const fetchDynamicLabel = async (functionName) => {
    switch (functionName) {
      case "getPlan":
        return getPlanDetails().alias;
      case "getBalance":
        return `${
          data.profile.accountBalance
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " credits"
        }`;
      default:
        throw new Error(`Function "${functionName}" not found in API`);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#333333",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          minWidth: 700,
        }}
      >
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            padding: "6px 0",
          }}
        >
          <Group spacing="md" style={{ marginRight: "35px" }}>
            <Anchor href="/">
              <Image src={configJson.logo} alt="Logo" height={35} />
            </Anchor>
          </Group>

          <Group spacing="md" style={{ flexGrow: 1 }}>
            {renderLinkItems(leftItems, "left")}
          </Group>

          <Group spacing="md" style={{ flexGrow: 1 }}>
            {renderLinkItems(centerItems, "center")}
          </Group>

          <Group spacing="md" style={{ marginLeft: "auto" }}>
            {renderLinkItems(rightItems, "right")}
          </Group>
        </Container>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <BasicModal
          size="auto"
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          centered
        >
          {ModalContent}
        </BasicModal>
      </Suspense>
    </>
  );
};

export default Header;
